import { Joyhttp } from '../fetch.js';
import config from '@/api/config';
export const homework = {
  grade_list (postData, checkLogin) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Material/grade_list_v3',
        postData,
        config.timeout,
        checkLogin
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_series_list (postData, checkLogin) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Material/get_series_list',
        postData,
        config.timeout,
        checkLogin
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_grade_list (postData, checkLogin) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Material/get_grade_list',
        postData,
        config.timeout,
        checkLogin
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  grade_list_filters (checkLogin) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Material/grades_filter',
        {},
        config.timeout,
        checkLogin
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        } else {
          reject();
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  grade_list_retake (postData, APPVERSION) {
    let url = '/interface/Material/grade_list_retake_v5';
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        url,
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_latest_retake (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/hybrid/Homework_Interface/get_student_current_retake',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  homework_below_grade (postData) {
    var url = '/interface/Homework/homework_below_grade_v4';
    if (postData.isretake == 1) {
      var url = '/interface/Homework/homework_below_grade_retake_v4';
    }
    return new Promise((resolve, reject) => {
      Joyhttp.post(url, postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007
              && result.errcode != 1008) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        })
        .catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  homework_result_details (postData, url) {
    let reqUrl = url || '/interface/Homework/homework_result_details_v7';
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        reqUrl,
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  homework_result_details_retake (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Homework/homework_result_details_retake_v1',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  homework_result_details_retake_preview (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Homework/homework_result_details_retake_preview',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  }
};

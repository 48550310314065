import quick from '../quick.js';
export const mediaDownload = {
  startDownLoad (data) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'startDownLoad',
        mudule: 'material',
        isLongCb: 0,
        data: data
      };
      quick.callApi(postData).then(result => {
        console.log(result);
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  queryDownLoad (data) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'queryDownLoad',
        mudule: 'material',
        isLongCb: 0,
        data: data
      };
      quick.callApi(postData).then(result => {
        console.log(result);
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  queryUnitList (data) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'queryUnitList',
        mudule: 'material',
        isLongCb: 0,
        data: data
      };
      quick.callApi(postData).then(result => {
        console.log(result);
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  queryAudioList (data) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'queryAudioList',
        mudule: 'material',
        isLongCb: 0,
        data: data
      };
      quick.callApi(postData).then(result => {
        console.log(result);
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  deleteUnitList (data) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'deleteUnitList',
        mudule: 'material',
        isLongCb: 0,
        data: data
      };
      quick.callApi(postData).then(result => {
        console.log(result);
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  }

};
import quick from '../quick.js'
export const homework = {
    openHomeWork(sendData){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'openHomeWork', 
                mudule: 'homework',
                isLongCb: 0, 
                data: sendData
            }
            quick.callApi(postData).then(result=>{  
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        }) ;  
    },
}
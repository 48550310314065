import { http, Joyhttp } from '../fetch.js';
import config from '@/api/config';

//教师
export const myself = {
  call_history (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post('/interface/hybrid/Call/history', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_profile_details () {
    return new Promise((resolve, reject) => {
      http.get('/app/interface/profile/get_profile_details_edit')
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  special_agents (postData, checkLogin) {
    return new Promise((resolve, reject) => {
      Joyhttp.post('/interface/Student/privilege_version', postData, config.timeout, checkLogin)
        .then(result => {
          if (result) {
            if (result.status != 200 && result.status != 1007 && result.status != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },

  hybrid_profile (postData, checkLogin) {
    return new Promise((resolve, reject) => {
      Joyhttp.post('/interface/Student/hybrid_profile_v3', postData, config.timeout, checkLogin)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
};
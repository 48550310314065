import quick from '../quick.js'
export const upload = {
  uploadLogs(item) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'uploadLogs',
        mudule: 'upload',
        isLongCb: 0,
        data: item
      }
      quick.callApi(postData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  }
}
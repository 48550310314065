import { Joyhttp } from '../fetch.js';
export const material = {
  get_grade_materials (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Material/get_grade_materials',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },

  material_audio_list (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Material/material_audio_list',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  }
};

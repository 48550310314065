import { http, Joyhttp } from '../fetch.js';

//登录
export const login = {
  login (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/login/login_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  //登录获取验证码
  login_getcode (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/Login/requestcode_v2',
        postData
      ).then(result => {
        if (result) {
          resolve(result);
        }
      }).catch(function (error) {
        let errmsg = error.errmsg || error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 登陆页设置密码
  set_password (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/login/set_password',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  //注册获取验证码
  sign_in_getcode (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/mobile/auth/user_register/send_code',
        postData
      ).then(result => {
        if (result) {
          resolve(result);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  sign_in (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/mobile/auth/user_register/reg_submit',
        postData
      ).then(result => {
        if (result) {
          if (result.msg == '注册成功') {
            resolve(result);
          } else {
            reject(result.msg);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  logout () {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/login/logout',
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            localStorage.clear();
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  special_agents (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post('/interface/Student/privilege_version', postData)
        .then(result => {
          if (result) {
            if (result.status != 200 && result.status != 1007 && result.status != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  //设置密码获取验证码
  reset_getcode (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/login/requestregcode_v2',
        postData
      ).then(result => {
        if (result) {
          resolve(result);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  //设置密码
  reset (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/login/reset_password',
        postData
      ).then(result => {
        if (result) {
          resolve(result);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  autoLogin (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/Login/auto_login',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
};
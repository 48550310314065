import quick from '../quick.js'
export const appRequire = {
    post(data){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'asynPost', 
                mudule: 'okhttp', 
                isLongCb: 0, 
                data: data
            }
            quick.callApi(postData).then(result=>{  
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        }) ;  
    },
    get(data){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'asynGet', 
                mudule: 'okhttp', 
                isLongCb: 0, 
                data: data
            }
            quick.callApi(postData).then(result=>{  
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        }) ;  
    }
}
import { http } from '../fetch.js';

export const preview = {
  add_material (lesson_id) {
    return new Promise((resolve, reject) => {
      let postData = {
        lesson_id: lesson_id
      };
      http.post('/app/interface/preview/count_material', postData)
        .then(result => { resolve(result); })
        .catch(error => { reject(error); });
    });
  },
  add_recording (lesson_id) {
    return new Promise((resolve, reject) => {
      let postData = {
        lesson_id: lesson_id
      };
      http.post('/app/interface/preview/count_recording', postData)
        .then(result => { resolve(result); })
        .catch(error => { reject(error); });
    });
  },
};

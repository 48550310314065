import quick from '../quick';

//登录
export const general = {
  logout () {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'logout',
        mudule: 'auth',
        isLongCb: 0,
        data: {}
      };
      quick.callApi(sendData).then(result => {
        localStorage.clear();
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  }

};

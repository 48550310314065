import { http } from '../fetch.js';
import { validate_api } from '@/api/validate_api';
//toc booking
export const toc_booking = {
  trial_lesson_time_sections (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/trial_lesson_time_sections',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },

  submit_trial_lesson (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_trial_lesson',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },

  trial_lesson_list () {
    let url = '/app/interface/lessons/trial_lesson_list';
    return validate_api(url);
  }
};
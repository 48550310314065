import { Joyhttp } from '../fetch.js';
//课程
export const offlineLesson = {
  get_pending_classes (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Student_class/get_offline_awaiting_lessons',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_make_up_classes (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Student_class/get_offline_makeup_lessons',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_taken_classes (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Student_class/get_offline_taken_lessons',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_absent (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Student_class/get_offline_absent_lessons',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  }
}; 
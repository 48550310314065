import {http} from './fetch.js'
export function validate_api (url, postData) {
    return new Promise((resolve, reject) => {
        var xhr_post
        if (postData) {
            xhr_post = http.post(url, postData)
        } else {
            xhr_post = http.post(url)
        }
            xhr_post.then(result => {
                if ( result ) {
                    if (result.errcode != 200 && result.errcode != 1007 
                        && result.errcode != 1008) {
                        reject(result.errmsg);
                    } else {
                        resolve(result.data);
                    }
                }
            })
            .catch(error => {
                let errmsg = error.message || error.msg || error ;
                reject( errmsg );
            })
    })
}

import { http, Joyhttp } from '../fetch.js';

//教师
export const profile = {
  getPH (postData) {
    let url = '/interface/purchase/PurchasedHistory/getPurchasedPrevilege';
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        url,
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_profile_details_edit () {
    return new Promise((resolve, reject) => {
      http.get('/app/interface/profile/get_profile_details_edit')
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_profile_details_edit_v2 (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/profile/get_profile_details_edit_v2', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_untaken_classes_count () {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/lessons/get_untaken_classes_count')
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007
              && result.errcode != 1008) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  validate_new_mobile (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Profile/validate_new_mobile', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  generate_vcode (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Profile/generate_vcode', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  check_vcode (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Profile/check_vcode', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  update_student (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/profile/update_student', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  update_student_v2 (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/profile/update_student_v2', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007 && result.errcode != 1008) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_points_history (postData, type) {
    let url;
    if (type === 'offline') {
      url = '/app/interface/Lessons/get_lesson_point_history_offline';
    } else {
      url = '/app/interface/Lessons/get_lesson_point_history_v2';
    }
    return new Promise((resolve, reject) => {
      http.post(
        url,
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  check_session () {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/profile/check_session')
        .then(result => {
          if (result && result.errcode == 200) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_student_session_info ($postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/profile/get_student_session_info', $postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            }
            else {
              resolve(result.data);
            }
          }
        })
        .catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  }
};

import qs from 'qs';
import config from '@/api/config';
import { Auth, AppRequire } from '@/api/deviceBridge';

function redirectLogin () {
  if (!window.Vue.pageStopped) {
  }
}

function getThisPage () {
  let thisPage = window.Vue;
  if (window.Vue) { thisPage = window.Vue; console.log('Vue'); }
  else if (window.Vue_class_list) { thisPage = window.Vue_class_list; console.log('Vue_class_list'); }
  else if (window.Vue_ai_lesson) { thisPage = window.Vue_ai_lesson; console.log('Vue_ai_lesson'); }
  else if (window.Vue_profile) { thisPage = window.Vue_profile; console.log('Vue_profile'); }
  else if (window.Vue_login) { thisPage = window.Vue_login; console.log('Vue_login'); }
  else if (window.Vue_reset) { thisPage = window.Vue_reset; console.log('Vue_reset'); }
  else if (window.Vue_audio) { thisPage = window.Vue_audio; console.log('Vue_audio'); }
  else if (window.Vue_booking) { thisPage = window.Vue_booking; console.log('Vue_booking'); }
  else if (window.Vue_calendar) { thisPage = window.Vue_calendar; console.log('Vue_calendar'); }
  else if (window.Vue_call_history) { thisPage = window.Vue_call_history; console.log('Vue_call_history'); }
  else if (window.Vue_homework) { thisPage = window.Vue_homework; console.log('Vue_homework'); }
  else if (window.Vue_notification) { thisPage = window.Vue_notification; console.log('Vue_notification'); }
  else if (window.Vue_online_grade) { thisPage = window.Vue_online_grade; console.log('Vue_online_grade'); }
  else if (window.Vue_purchase) { thisPage = window.Vue_purchase; console.log('Vue_purchase'); }
  else if (window.Vue_teachers_list) { thisPage = window.Vue_teachers_list; console.log('Vue_teachers_list'); }
  return thisPage;
}

export const appJoy = {
  post (url, data, timeOut, checkLogin) {
    if (window.debugFlag) {
      console.log(arguments);
    }
    if (!config.requestCount["urljoy"]) config.requestCount["urljoy"] = 0;

    return new Promise((resolve, reject) => {

      let postData = {
        url: config.joy + url,
        body: data || {},
        header: {}
      };

      AppRequire.post(postData).then(httpRequert => {
        if (window.debugFlag) {
          console.log(httpRequert);
        }
        let result = null;
        if (config.responseType == 'json') {
          //判定数据格式
          try {
            JSON.stringify(httpRequert);
            result = httpRequert;
          } catch (e) {
            config.utalkTools.logs("系统故障数据接口返回格式错误" + httpRequert, 'error');
            result = {
              errcode: 9876,
              errmsg: '数据格式错误! 请反馈给技术支持'
            };
          }
          //判定数据状态
          if (result && result.errcode && result.errcode == 1004) {
            if (config.requestCount["urljoy"] < 3) {
              config.requestCount["urljoy"]++;
              return new Promise((resolve, reject) => {
                Auth.getAccount().then(result => {
                  getThisPage().$store.commit('saveLogin', result);
                  resolve(true);
                }).catch(error => {
                  reject('设备错误! 要不更新一下软件试试呢!');
                });
              }).then(result => {
                return new Promise((resolve, reject) => {
                  appJoy.post(url, data).then(result => {
                    config.requestCount["urljoy"] = 0;
                    resolve(result);
                  }).catch(error => {
                    reject(error);
                  });
                });
              }).catch(error => {
                reject(error);
              });
            } else {
              config.requestCount["urljoy"] = 0;
              reject('设备错误! 要不清一下软件缓存试试呢!');
            }
          } else if (result) {
            config.requestCount["urljoy"] = 0;
            resolve(result);
          }
        } else {
          result = httpRequert;
        }
        resolve(result);
      }).catch(error => {
        if (config.requestCount["urljoy"] < 3) {
          config.requestCount["urljoy"]++;
          setTimeout(() => {
            appJoy.post(url, data).then(result => {
              config.requestCount["urljoy"] = 0;
              resolve(result);
            }).catch(error => {
              reject(error);
            });
          }, 2000);
        } else {
          config.requestCount["urljoy"] = 0;
          reject('网络信号不佳');
        }
        // config.utalkTools.logs('apprequest 程序捕获异常' + JSON.stringify(error), 'error');
        // console.log("#R3p34+")
        // reHttpjoy.post( reUrl, reData )
        // 	.then ( res => {
        // 		resolve ( res )
        // 	} )
        // 	.catch ( err => {
        // 		console.log ( "rerun error" );
        // reject('网络信号不佳');
        // } )
      });
    });
  },
  get (url, params = {}, timeOut, checkLogin) {
    let redirect = true;
    if (typeof checkLogin !== 'undefined') {
      redirect = checkLogin;
    }
    if (window.debugFlag) {
      console.log(arguments);
    }
    if (!config.requestCount["urljoyGet"]) config.requestCount["urljoyGet"] = 0;
    return new Promise((resolve, reject) => {
      Auth.getAccount().then(result => {
        if (!result) {
          if (redirect) {
            redirectLogin();
          }
          config.requestCount["urljoyGet"] = 0;
          reject('设备错误! 要不更新一下软件试试呢!');
        }
      });
      let postData = {
        url: config.joy + url + '?' + qs.stringify(params),
        body: {},
        header: {}
      };
      AppRequire.post(postData).then(httpRequert => {
        if (window.debugFlag) {
          console.log(httpRequert);
        }
        let result = null;
        if (config.responseType == 'json') {
          //判定数据格式
          try {
            JSON.stringify(httpRequert);
            result = httpRequert;
          } catch (e) {
            config.utalkTools.logs("系统故障数据接口返回格式错误" + result, 'error');
            result = {
              errcode: 9876,
              errmsg: '数据格式错误! 请反馈给技术支持'
            };
          }
          //判定数据状态
          if (result && result.errcode && result.errcode == 1004) {
            if (config.requestCount["urljoyGet"] < 3) {
              config.requestCount["urljoyGet"]++;
              return new Promise((resolve, reject) => {
                Auth.getAccount().then(result => {
                  getThisPage().$store.commit('saveLogin', result);
                  resolve(true);
                }).catch(error => {
                  reject('设备错误! 要不更新一下软件试试呢!');
                  config.utalkTools.logs('设备接口返回错误' + error, 'error');
                });
              }).then(result => {
                return new Promise((resolve, reject) => {
                  appJoy.get(url, params).then(result => {
                    config.requestCount["urljoyGet"] = 0;
                    resolve(result);
                  }).catch(error => {
                    reject(error);
                  });
                });
              }).catch(error => {
                reject(error);
              });
            } else {
              config.requestCount["urljoyGet"] = 0;
              config.utalkTools.logs('设备接口返回错误 无法刷新登录 获取数据', 'error');
              reject('设备错误! 要不清一下软件缓存试试呢!');
            }
          } else if (result) {
            resolve(result);
          }
        } else {
          result = httpRequert;
        }
        resolve(result);
      }).catch(error => {
        if (config.requestCount["urljoyGet"] < 3) {
          config.requestCount["urljoyGet"]++;
          setTimeout(() => {
            appJoy.get(url, params).then(result => {
              config.requestCount["urljoyGet"] = 0;
              resolve(result);
            }).catch(error => {
              reject(error);
            });
          }, 2000);
        } else {
          config.requestCount["urljoyGet"] = 0;
          config.utalkTools.logs('apprequest 程序捕获异常' + JSON.stringify(error), 'error');
          reject(error);
        }
        // config.utalkTools.logs('apprequest 程序捕获异常' + JSON.stringify(error), 'error');
        // reject(error);
      });
    });
  }
};
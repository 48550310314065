import quick from '../quick.js'
export const media = {
    playTeacherAudio(item){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'playTeacherAudio', 
                mudule: 'media', 
                isLongCb: 0, 
                data: item
            }
            quick.callApi(postData).then(result=>{  
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        }) ;  
    }
}

import { http, payHttp } from '../fetch.js';
//主页
export const privilege = {
  get_list (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/privilege/index',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  details (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/privilege/details',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  add_order (postData) {
    return new Promise((resolve, reject) => {
      payHttp.post(
        '/createOrder.do',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  //新版创建订单 走菲方php接口
  add_order_v2 (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/mobile/pay/order/save_order',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  //新版创建订单 走v2版 java 支付
  pay_create_order (postData) {
    return new Promise((resolve, reject) => {
      payHttp.post(
        '/createOrder.do',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  payorder (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/order/payorder',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  aliPay (postData) {
    return new Promise((resolve, reject) => {
      payHttp.post(
        '/app/aliPay.do',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  aliqrPay (postData) {
    return new Promise((resolve, reject) => {
      payHttp.post(
        '/view/aliPay.do',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  wxwebPay (postData) {
    return new Promise((resolve, reject) => {
      payHttp.post(
        '/web/wxPay.do',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  wxPay (postData) {
    return new Promise((resolve, reject) => {
      payHttp.post(
        '/app/wxPay.do',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  orderlist (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/order/orderlist',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  cancel_order (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/order/cancel_order',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  }
};
import config from './config.js';
import quick from '@/utils/jsBridge/quick';
quick.error(function (error) {
  config.utalkTools.logs('quick初始化错误' + error.msg, 'error');
});
quick.config({
  jsApiList: ['auth', 'homework', 'live', 'media', 'page', 'pay', 'runtime', 'share', 'material']
});

function getThisPage () {
  let thisPage = window.Vue;
  if (window.Vue) { thisPage = window.Vue; console.log('Vue'); }
  else if (window.Vue_class_list) { thisPage = window.Vue_class_list; console.log('Vue_class_list'); }
  else if (window.Vue_ai_lesson) { thisPage = window.Vue_ai_lesson; console.log('Vue_ai_lesson'); }
  else if (window.Vue_profile) { thisPage = window.Vue_profile; console.log('Vue_profile'); }
  else if (window.Vue_login) { thisPage = window.Vue_login; console.log('Vue_login'); }
  else if (window.Vue_reset) { thisPage = window.Vue_reset; console.log('Vue_reset'); }
  else if (window.Vue_audio) { thisPage = window.Vue_audio; console.log('Vue_audio'); }
  else if (window.Vue_booking) { thisPage = window.Vue_booking; console.log('Vue_booking'); }
  else if (window.Vue_calendar) { thisPage = window.Vue_calendar; console.log('Vue_calendar'); }
  else if (window.Vue_call_history) { thisPage = window.Vue_call_history; console.log('Vue_call_history'); }
  else if (window.Vue_homework) { thisPage = window.Vue_homework; console.log('Vue_homework'); }
  else if (window.Vue_notification) { thisPage = window.Vue_notification; console.log('Vue_notification'); }
  else if (window.Vue_online_grade) { thisPage = window.Vue_online_grade; console.log('Vue_online_grade'); }
  else if (window.Vue_purchase) { thisPage = window.Vue_purchase; console.log('Vue_purchase'); }
  else if (window.Vue_teachers_list) { thisPage = window.Vue_teachers_list; console.log('Vue_teachers_list'); }
  return thisPage;
}
quick.ready(function () {
  setTimeout(() => {
    if (window.Vue || window.Vue_class_list || window.Vue_ai_lesson || window.Vue_profile) {
      let postData = {
        name: 'onAppEvent',
        mudule: 'page',
        isLongCb: 0,
        data: { event: "CLEAR_LOAD_TIME" }
      };
      quick.callApi(postData).then(result => {
        console.log(result, 'onAppEvent');
      }).catch(error => {
        console.log(error, 'onAppEvent');
      });
    }
  });

  if (window.JSBridge && typeof window.JSBridge.registerHandler == 'function') {
    window.JSBridge.registerHandler('onPageEvent', result => {
      console.log(result,'onPageEventonPageEvent');
      if (result && result.screenEvent) {
        // 主题色更换
        if (window && getThisPage() && getThisPage().$store)
          getThisPage().$store.commit('setScreenTheme', result.screenEvent);
      }
      if (result && result.event) {
        if (result.event == "GONE") {
			if ( getThisPage().gonePage && typeof getThisPage().gonePage === 'function' ) {
				getThisPage().gonePage();
			}
          // 隐藏弹窗
          if (window && window.Vue_profile && window.Vue_profile.$store)
            window.Vue_profile.$store.commit('setShow_val_modal', false);
        }
        if (result.event == "RELOGIN_ING") {
          // 首页下方显示未连接弹窗
          if (window && window.Vue && window.Vue.$store)
            window.Vue.$store.commit('setShow_network_modal', true);
        }
        if (result.event == "RELOGIN_OK") {
          // 首页下方关闭未连接弹窗
          if (window && window.Vue && window.Vue.$store)
            window.Vue.$store.commit('setShow_network_modal', false);
        }
      }
      return "ok";
    });
    window.JSBridge.registerHandler('loginCallback', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      //config.utalkTools.logs('APP调用JS数据' + JSON.stringify(result), 'error');
      return "ok";
    });
    window.JSBridge.registerHandler('refResh', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      if (window && getThisPage() && getThisPage().$router)
        getThisPage().$router.push(result);
      return "ok";
    });
    window.JSBridge.registerHandler('VideoStart', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      if (window && getThisPage() && getThisPage().$store)
        getThisPage().$store.commit('setMaterialAudioIndex', result.index + '');
      //config.utalkTools.logs('APP调用音频开始' + JSON.stringify(result), 'error');
      return "ok";
    });
    window.JSBridge.registerHandler('VideoPause', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      if (window && getThisPage() && getThisPage().$store)
        getThisPage().$store.commit('setMaterialAudioIndex', 9999);
      //config.utalkTools.logs('APP调用音频暂停' + JSON.stringify(result), 'error');
      return "ok";
    });
    window.JSBridge.registerHandler('VideoEnd', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      if (window && getThisPage() && getThisPage().$store)
        getThisPage().$store.commit('setMaterialAudioIndex', 9999);
      //config.utalkTools.logs('APP调用音频结束' + JSON.stringify(result), 'error');
      return "ok";
    });
    window.JSBridge.registerHandler('getVersion', result => {
      return process.env.BUILD_VER;
    });
    window.JSBridge.registerHandler('onResumeHome', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      //config.utalkTools.logs('APP唤醒刷新' + JSON.stringify(result), 'error');
      return "ok";
    });
    window.JSBridge.registerHandler('renderHybrid', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      if (window && getThisPage() && getThisPage().$store)
        getThisPage().$store.commit('setTimestamp', parseInt(result.timestamp / 1000));
      //config.utalkTools.logs('APP定时器' + JSON.stringify(result), 'error');
      return "ok";
    });

    window.JSBridge.registerHandler('onPageResume', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      getThisPage().pageStopped = false;
      getThisPage().resumePage();
      return "ok";
    });

    window.JSBridge.registerHandler('onPageStop', result => {
      if (window.debugFlag) {
        console.log(result);
      }
      getThisPage().pageStopped = true;
      getThisPage().stopPage();
      return "ok";
    });
  }
});
export default quick;
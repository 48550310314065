const newHybridAppId = 10000000;
const hwh5appid = 10000200;
const eth5appid = 10000500;
const awardh5appid = 10000600; // AWARDS
const tkh5appid = 10000700;
const pilotCourseh5appid = 10000800;
const curentAppToken = 'Xd09s6LnF9P9h2Ilda36yACV';
const imPlayback = 'http://im-playback.helputalk.com/studio.html';

const utalkTools = {
  logs: function (message) {
    console.log(message);
  }
};

export default {
  runenv: process.env.VUE_APP_RUNENV,
  curentAppToken: curentAppToken,
  newHybridAppId: newHybridAppId,
  api: process.env.VUE_APP_SERVER,
  hybridJoyServer: process.env.VUE_APP_HYBRIDJOYSERVER,
  hybridJoyServerOffline: process.env.VUE_APP_HYBRIDJOYSERVEROFFLINE,
  joy: process.env.VUE_APP_JOYSERVER,
  pay: process.env.VUE_APP_PAYMENT,
  paycenter: process.env.VUE_APP_PAYCENTER,
  queueUrl: process.env.VUE_APP_QUEUEURL,
  timeout: 30000,
  utalkTools: utalkTools,
  responseType: 'json',
  requestCount: {},
  eduAttachment: process.env.VUE_APP_EDUATTACHMENT,
  deviceTestServer: process.env.VUE_APP_DEVICETESTSERVER,
  deviceTestResult: process.env.VUE_APP_DEVICETESTRESULT,
  eth5appid: eth5appid,
  checkGuide: process.env.VUE_APP_CHECKGUIDE,
  pilotCourse: process.env.VUE_APP_PILOTCOURSE,
  pilotCourseh5appid: pilotCourseh5appid,
  tkh5appid: tkh5appid,
  classroomTicket: process.env.VUE_APP_CLASSROOMTICKET,
  cmsUrl: process.env.VUE_APP_CMSURL,
  activityUrl: process.env.VUE_APP_ACTIVITYURL,
  hwh5appid: hwh5appid,
  homeworkServer: process.env.VUE_APP_HOMEWORKSERVER,
  activityServer: process.env.VUE_APP_ACTIVITYSERVER,
  awardServer: process.env.VUE_APP_AWARDSERVER,
  awardh5appid: awardh5appid,
  wechatServer: process.env.VUE_APP_WECHATSERVER,
  aiLesson: process.env.VUE_APP_AILESSON,
  dataCenter: process.env.VUE_APP_DATACENTER,
  imPlayback: imPlayback,
  mediaCenter: process.env.VUE_APP_MEDIACENTER
};

import quick from "../quick.js";
export const privacy = {
  querySetting() {
    return new Promise((resolve, reject) => {
      let postData = {
        name: "querySetting",
        mudule: "setting",
        isLongCb: 0,
      };
      quick
        .callApi(postData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  openSetting(data) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: "openSetting",
        mudule: "setting",
        isLongCb: 0,
        data: data
      };
      quick
        .callApi(postData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

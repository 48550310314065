import { mediaCenterHttp } from "../fetch.js";
export const notify = {
  findAuth(params) {
    return new Promise((resolve, reject) => {
      mediaCenterHttp
        .get("/Message-Auth-findAuth", params)
        .then((res) => {
          if (res.code != 200) {
            reject(res.msg);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log(err, '错误')
          reject(err);
        });
    });
  },
  editAuth(data) {
    return new Promise((resolve, reject) => {
      mediaCenterHttp
        .post("/Message-Auth-editAuth", data)
        .then((res) => {
          if (res.code != 200) {
            reject(res.msg);
          } else {
            resolve(res.msg);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

let site = window.location.href.split('?')[0].match(/[http|https]+:\/\/([a-z0-9\.\-]+)/);
let serverHost = 'production';

if (site !== null) {
	if ( site[1] == 'sedu.helputalk.com' ) {
		serverHost = 'production';
	} else if ( site[1] == 'grayci.helputalk.com' ) {
		serverHost = 'test';
	} else {
		serverHost = 'local';
	}
}

// Instances
// booking type images
let bookingImages = {
	num1: '',
	num2: '',
	num4: '',
	num5: '',
};

//default avatar
let defaultAvatarImage 	= "";
let defaultAvatarImageF = "";
let teacherAvatar		= "";

// Profile Background
let profileBackground = "";

// Edit Profile Background
let editProfileBack = "";

// booking images
bookingImages.num1 = require('@/assets/imgs/common/num1_joy.png');
bookingImages.num2 = require('@/assets/imgs/common/num2_joy.png');
bookingImages.num4 = require('@/assets/imgs/common/num4_joy.png');
bookingImages.num5 = require('@/assets/imgs/common/num5_joy.png');

// default avatar
defaultAvatarImage 	= require( '@/assets/imgs/common/j_ava.jpg' );
defaultAvatarImageF = require( '@/assets/imgs/common/j_ava.jpg' );
teacherAvatar 		= require( '@/assets/imgs/common/j_ava.jpg' );

// Profile Background
profileBackground = require("@/assets/imgs/common/ban_back.jpg");

// Edit Profile Background
editProfileBack = require('@/assets/imgs/common/background.png');


export const bookingTypeImages 			= bookingImages;
export const defaultAvatar 				= defaultAvatarImage;
export const defaultAvatarF 			= defaultAvatarImageF;
export const defaultTeacherAvatar 		= teacherAvatar;
export const profileBackgroundImage 	= profileBackground;
export const editProfileBackgroundImage = editProfileBack;
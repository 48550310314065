// 简单json数据深拷贝
export const deepClone = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  let newObj = obj instanceof Array ? [] : {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key];
    }
  }
  return newObj;
};
// 节流
export const throttle = (func, wait) => {
  let context, args;
  let previous = 0;
  return function() {
    let now = Date.now();
    context = this;
    args = arguments;
    if (now - previous > wait) {
      previous = now;
      func.apply(context, args);
    }
  };
};

export const httpTos = (baseUrl = "") => {
  if (/^http:\/\//.test(baseUrl)) {
    baseUrl = baseUrl.replace(/^http:\/\//, "https://");
  }
  return baseUrl;
};

export const siteEnvColor = () => {
  switch (siteEnv()) {
    case 'utalk': return '#ffffff'; break;
    case 'joy': return '#ffffff'; break;
    case 'cn': return '#ffffff'; break;
  }
};
export const siteEnv = () => {
  if (process.env.VUE_APP_TYPE) {
    return process.env.VUE_APP_TYPE;
  }
  if (getUrlParam('theme')) {
    return getUrlParam('theme');
  }
  if (getUrlPath(1)) {
    return getUrlPath(1);
  }
  return 'joy';
};
export const getUrlPath = (number = 1) => {
  var params = decodeURI(window.location.pathname);
  var reg = new RegExp(/^\/template\/([\w]+)/);
  var r = params.match(reg);
  if (r != null) return unescape(r[number]);
  return '';
};
export const getUrlParam = (name = 'id') => {
  var params = decodeURI(window.location.search);
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = params.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return '';
};
export const isMobile = /Android|Windows Phone|webOS|iPhone|iPod|iPad|Linux|Touch|BlackBerry/i.test(navigator.userAgent);
export const isIpad = () => /iPad/i.test(navigator.userAgent);
export const getUUID = (prefix = 'h5image/paste/') => {
  let date = new Date();
  let month = date.getMonth() + 1;
  let theday = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let miniseco = date.getMilliseconds();
  let randoms = (((1 + Math.random()) * 0x10000) | 0);
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (theday >= 0 && theday <= 9) {
    theday = "0" + theday;
  }
  if (hours >= 0 && hours <= 9) {
    hours = "0" + hours;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  //年月日时分秒_毫秒随机数
  return prefix + date.getFullYear() + month + theday + hours + minutes + seconds + '_' + miniseco + randoms;
};
import { http, dataCenterHttp } from '../fetch.js'
import config from '@/api/config'
import SparkMD5 from 'spark-md5'
//课程
export const lesson = {
  user: "web_put_user",
  key: "2f3d53a6-0554-11e8-bcf1-702084e1f452",
  getToken (postData = {}) {
    let time = new Date().getTime() / 1000;
    return Object.assign(postData,
      {
        token: SparkMD5.hash(this.user + time + this.key),
        time: time,
        user: this.user
      }
    );
  },
  // 获取课程回放录屏
  get_video (postData) {
    return new Promise((resolve, reject) => {
      dataCenterHttp.post(
        '/interface/log/studentVideoList',
        this.getToken(postData)
      ).then(result => {
        if (result) {
          if (result.code != 200) {
            reject(result.message);
          } else {
            resolve(result.data);
          }
        } else {
          reject(result.message);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_untaken_classes_preview (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/get_untaken_class_preview',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_taken_classes (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/get_taken_class_preview',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008 && result.errcode != 1001) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_untaken_classes (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/Lessons/get_untaken_classes_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        } else {
          reject(result.errmsg);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_cancelled_mk_classes (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/get_cancelled_mk_classes',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        } else {
          reject(result.errmsg);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_taken_classes_v2 (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/get_taken_classes_v3',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        } else {
          reject(result.errmsg);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_cancelled_no_mk_grp_classes (postData, checkLogin) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/get_cancelled_no_mk_grp_classes',
        postData,
        config.timeout,
        checkLogin
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        } else {
          reject(result.errmsg);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_cancelled_no_mk_ind_classes (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/get_cancelled_no_mk_ind_classes',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        } else {
          reject(result.errmsg);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  check_in_student (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/check_in_student',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_current_unixtime () {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Lessons/get_current_unix').then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // all interfaces related to lesson details
  // -- start edit lesson --
  // 1: get the list of grades
  get_grades_for_edit (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/get_grades_for_edit',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 2: get the materials of the selected grade using Booking.get_grade_materials(postData) from api/interface/booking.js
  // 3: submit the changes for the lesson
  edit_lesson (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/lessons/edit_lesson_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // -- end of edit lesson --

  // --start of lesson cancel --
  check_cancel_chance () {
    return new Promise((resolve, reject) => {
      http.get('/app/interface/lessons/get_cancel_chance').then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },

  // Individual Class Cancel
  icc_check_status (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/lessons/cancel_class', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },

  icc_confirm (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/lessons/cancel_per_class_confirm', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },

  icc_submit (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/lessons/cancel_per_class_submit', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },

  // Group Class Cancel
  gcc_get_list () {
    return new Promise((resolve, reject) => {
      http.get('/app/interface/lessons/cancel_per_group_confirm').then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },

  gcc_confirm (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/lessons/cancel_per_group_info', postData).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },

  gcc_checker () {
    return new Promise((resolve, reject) => {
      http.get('/app/interface/lessons/cancel_per_group_confirm3').then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },

  gcc_cancel (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/lessons/cancel_per_group_submit', postData).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // -- end of lesson cancel --

  //comment
  comment_get_tags (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/comment/get_tag', postData).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  comment_anonymous_get_tags () {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/comment/anonymous_tag').then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  comment_submit (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/comment/commentsubmit', postData).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  comment_anonymous_submit (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/comment/anonymous_submit', postData).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  comment_view (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/comment/comment_view', postData).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(error => {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  can_be_delayed (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Delay_class/can_be_delayed', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },

  get_classes_tobe_cancelled (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Delay_class/classes_tobe_cancelled', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },

  submit_delay_class (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Delay_class', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(error => {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_grade_name (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Lessons/get_grade_name', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_lesson_time (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/Lessons/get_lesson_details', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        }).catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  add_comment_counter (lesson_id) {
    return new Promise((resolve, reject) => {
      let postData = {
        lesson_id: lesson_id
      };
      http.post('/app/interface/preview/count_comment', postData)
        .then(result => { resolve(result); })
        .catch(error => { reject(error); });
    });
  }
};

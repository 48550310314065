import { linkHttp } from '../fetch.js'
import { siteEnv } from '@/utils/tools'
import Url from '../config.js'

export const advertisement = {
	get (studentId, agentId) {
		return new Promise((resolve, reject) => {
			let postData = {
				from: siteEnv().includes('utalk') ? 'utalk' : 'joy',
				position: 'ADVERTISEMENT',
				student_id: studentId,
				agent_id: agentId,
			};
			linkHttp.post(Url['cmsUrl'] + '/interface/api/cms/ad', postData)
				.then(result => { resolve(result) })
				.catch(error => { reject(error) })
		});
	},
	get_second_ad (studentId) {
		return new Promise((resolve, reject) => {
			let postData = {
				type: siteEnv().includes('utalk') ? 'utalk' : 'joy',
				student_id: studentId
			};
			linkHttp.post(Url['activityUrl'] + '/medal/medal/popupList', postData)
				.then(result => { resolve(result) })
				.catch(error => { reject(error) })
		});
	},
	get_banner (studentId, agentId) {
		return new Promise((resolve, reject) => {
			let postData = {
				from: siteEnv().includes('utalk') ? 'utalk' : 'joy',
				position: 'BANNER',
				student_id: studentId,
				agent_id: agentId
			};
			linkHttp.post(Url['cmsUrl'] + '/interface/api/cms/ad', postData)
				.then(result => { resolve(result) })
				.catch(error => { reject(error) })
		});
	},
}

<template>
  <svg version="1.1" :class="clazz" :role="label ? 'img' : 'presentation'" :aria-label="label" :viewBox="box" :style="style">
    <path :d="path.d" :fill="path.fill" :stroke="path.stroke" :stroke-width="path['stroke-width']" :stroke-miterlimit="path['stroke-miterlimit']" v-for="path in icon.paths"/>
  </svg>
</template>

<style>
  .svg-icon {
    display: inline-block;
    fill: currentColor;
  }

  .svg-icon.flip-horizontal {
    transform: scale(-1, 1);
  }

  .svg-icon.flip-vertical {
    transform: scale(1, -1);
  }

  .svg-icon.spin {
    animation: fa-spin 1s 0s infinite linear;
  }

  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

<script>

  import SVGtoArray from './lib/parse';
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      scale: [Number, String],
      spin: Boolean,
      flip: {
        validator: function (val) {
          return val === 'horizontal' || val === 'vertical'
        }
      },
      label: String,
      index: String,
      width: {
        type: String,
        default: '0'
      },
      height: {
        type: String,
        default: '0'
      },
      currentIndex: String
    },
    computed: {
      normalizedScale() {
        let scale = parseInt(window.document.querySelector('body').style.fontSize) / 12;
        return scale
      },
      clazz() {
        return {
          'svg-icon': true,
          spin: this.spin,
          'flip-horizontal': this.flip === 'horizontal',
          'flip-vertical': this.flip === 'vertical',
          active: this.index === this.currentIndex
        }
      },
      icon() {
        let xml = require(`!xml-loader!../../svg/${this.name}.svg`);
        const t = xml.svg.$.viewBox.split(' ');  
        return {
          width: t[2],
          height: t[3],
          paths: SVGtoArray(xml.svg)
        }
      },
      box() {
        return `0 0 ${this.icon.width} ${this.icon.height}`
      },
      _width() {
        if ( this.width != '0' ) {
            return this.width;
        } else {
            return this.normalizedScale
        }
      },
      _height() {
        if ( this.height != '0' ) {  
            return this.height;
        } else {
            return this.normalizedScale
        }
      },
      style() {        
        let style = {};
        if ( this._width ) {
            style.width = this._width;
        }
        if ( this._height ) {
            style.height = this._height;
        }
        style.fontSize = this.normalizedScale + 'rem';
        return style;
      }
    },
    register: function () {
      console.warn("inject deprecated since v1.2.0, SVG files can be loaded directly, so just delete the inject line.")
    },
  }
</script>

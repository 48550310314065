import config from '@/api/config'
import axios from 'axios'
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
	return config;
}, function (error) {  
	let ErrMsg = error.message || error.msg || error; 
	if (ErrMsg) {
		config.utalkTools.logs('系统请求错误' + ErrMsg, 'error');
		return Promise.reject(ErrMsg);
	}
});
// 添加响应拦截器
axios.interceptors.response.use(function (response) { 
	return response.data || {errcode:6789,errmsg:'请重新登录后再试一试'};
}, function (error) { 
	let ErrMsg = error.message || error.msg || error; 
	if ( ErrMsg ) {
		config.utalkTools.logs('系统故障错误' + ErrMsg, 'error');
		if (/code\s*500/i.test(ErrMsg)) {
			return Promise.reject('服务器累瘫了! 请及时反馈给技术支持'); 
		} else if (/Network/i.test(ErrMsg)) {
			return Promise.reject('您的设备没有网络 请稍后再试吧'); 
		} else {
			return Promise.reject('服务器繁忙! 请及时反馈给技术支持'); 
		}
	}
});
axios.defaults.withCredentials = true;

export default  axios;

import { http } from '../fetch.js';

export const booking = {
  get_booking_type () {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/Booking/booking_type_with_num'
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // checker if it is monday morning
  is_monday_morning () {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/is_monday_morning',
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_general_grade (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/get_general_grade_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  search_general_grade (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/search_general_grade',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_recommend_general_grade (postData) {
    return new Promise((resolve, reject) => {
      http.post('/app/interface/booking/get_general_grade_v3', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007
              && result.errcode != 1008) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        })
        .catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_grade_materials (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/select_unit_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  check_current_day () {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/check_current_day',
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // getting of timeslots based on lesson type
  num1_booking (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/book_class',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  num2_booking (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/num2_booking',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  num4_booking (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/num4_booking_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  num5_booking (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/num5_booking_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // confirmation of booking per lesson type
  submit_num1_confirm (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_booking/confirm',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  submit_num2_confirm (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_num2/confirm2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  submit_num4_confirm (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_num4_v2/confirm2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  submit_num5_confirm (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_num5_v2/confirm2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // submission of booking
  submit_num1 (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_booking',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  submit_num2 (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_num2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  submit_num4 (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_num4_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  submit_num5 (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/booking/submit_num5_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
};
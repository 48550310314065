import quick from '../quick.js'
export const mediaPannel = {
    showMediaPanel(data){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'showMediaPanel', 
                mudule: 'material', 
                isLongCb: 0, 
                data: data
            }
            quick.callApi(postData).then(result=>{  
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        }) ;  
    },
    playAudioList(data){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'playAudioList', 
                mudule: 'material', 
                isLongCb: 0, 
                data: data
            }
            
            quick.callApi(postData).then(result=>{
                resolve(result);
            }).catch(error=>{
                reject(error.msg);
            }); 
        }) ;  
    },
    pauseAudioList(data){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'pauseAudioList', 
                mudule: 'material', 
                isLongCb: 0, 
                data: data
            }
            quick.callApi(postData).then(result=>{
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        }) ;  
    },
    hideMediaPanel(data){
        return new Promise((resolve, reject) => {
            let postData = {
                name: 'hideMediaPanel', 
                mudule: 'material', 
                isLongCb: 0, 
                data: data
            }
            quick.callApi(postData).then(result=>{  
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        }) ; 
    }
}
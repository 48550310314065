import quick from '../quick.js';
import { Cache, Auth } from '@/api/deviceBridge.js';
import { Login } from '@/api/homepage';
import config from "@/api/config";

export const auth = {
  os: quick.os,
  getAccount () {
    return new Promise((resolve, reject) => {
      if (!Auth.os.h5) {
        let sendData = {
          name: 'getAccount',
          mudule: 'auth',
          isLongCb: 0,
          data: {}
        };

        quick.callApi(sendData)
          .then(result => {
            if (result) {
              Login.autoLogin({ studentid: result.uid, appToken: config.curentAppToken });

              Cache.readItem('sessionData')
                .then(session => {
                  if (result && !session) {
                    let postData = {
                      mobile: result.mobile,
                      appToken: config.curentAppToken
                    };

                    Login.login(postData)
                      .then(student => {
                        Cache.saveItem('sessionData', JSON.stringify(student));

                        let params = {
                          mobile: student.mobile ? student.mobile : student.imuid,
                          uid: student.uid,
                          uni_type: student.uni_type,
                          salt: student.salt,
                          status: student.status,
                          gender: student.gender,
                          avatar: student.avatar,
                          realname: student.realname,
                          nickname: student.nickname,
                          uniacid: student.uniacid,
                          acid: student.acid,
                        };

                        this.loginv2(params).then(result => {
                          Page.exitWebView({
                          }).then(result => {
                          }).catch(error => {
                            console.log(error);
                          });
                        }).catch(error => {
                          console.log(error);
                        }).finally(result => {

                        });

                        resolve(student);
                      }).catch(error => {
                      });

                  } else {
                    Cache.saveItem('sessionData', JSON.stringify(result));
                    resolve(result);
                  }

                });
            } else {
              resolve(result);
            }

          }).catch(error => {
            reject(error.msg);
          });
      } else {
        Cache.readItem('sessionData')
          .then(session => {
            if (session) {
              resolve(JSON.parse(session));
            } else {
              resolve(false);
            }
          });
      }
    });
  },
  login () {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'login',
        mudule: 'auth',
        isLongCb: 0,
        data: {}
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  loginv2 (params) {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'loginv2',
        mudule: 'auth',
        isLongCb: 0,
        data: params
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  openThePhoneCall (param) {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'openThePhoneCall',
        mudule: 'auth',
        isLongCb: 0,
        data: param
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  }
};
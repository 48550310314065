<template>
  <!-- class="empty-data" -->
  <div style="width: 100%;text-align: center;">
    <div style="width: 100%; height: 40vh;"></div>
    <img
      :src="require(`@/assets/imgs/common/nodata_${appType}.png`)"
      style="width:5rem;"
    />
    <p style="font-size:.7rem;margin-top: 1rem;color: #333;">暂无信息</p>
  </div>
</template>

<script>
import { siteEnv } from "@/utils/tools";
export default {
  name: "NoData",
  data() {
    return {
      appType: siteEnv()
    };
  }
};
</script>

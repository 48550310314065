import {
	Joyhttp,
	http
} from '../fetch.js';

export const infoList = {
		count_num(postData) {
			return new Promise((resolve, reject) => {
				http.post(
					'/app/interface/profile/count_num',
					postData
				).then(result => {
					if (result) {
						if (result.errcode != 200) {
							reject(result.errmsg);
						} else {
							resolve(result.data);
						}
					}
				}).catch(function(error) {
					let errmsg = error.message || error.msg || error;
					reject(errmsg);
				});
			});
		},
		get_notifications_v2(postData) {
			return new Promise((resolve, reject) => {
				http.post(
					'/app/interface/profile/get_notifications_v2',
					postData
				).then(result => {
					if (result) {
						if (result.errcode != 200) {
							reject(result.errmsg);
						} else {
							resolve(result.data);
						}
					}
				}).catch(function(error) {
					let errmsg = error.message || error.msg || error;
					reject(errmsg);
				});
			});
		},
		get_notification_complete(postData) {
			return new Promise((resolve, reject) => {
				http.post(
					'/app/interface/profile/get_notification_complete',
					postData
				).then(result => {
					if (result) {
						if (result.errcode != 200) {
							reject(result.errmsg);
						} else {
							resolve(result.data);
						}
					}
				}).catch(function(error) {
					let errmsg = error.message || error.msg || error;
					reject(errmsg);
				});
			});
		},
		
		clear_all_unread(postData) {
			return new Promise((resolve, reject) => {
				http.post(
					'/app/interface/profile/clear_all_unread',
					postData
				).then(result => {
					if (result) {
						if (result.errcode != 200) {
							reject(result.errmsg);
						} else {
							resolve(result.data);
						}
					}
				}).catch(function(error) {
					let errmsg = error.message || error.msg || error;
					reject(errmsg);
				});
			});
		},
		}

import qs from 'qs';
import config from '@/api/config';
import { Auth, AppRequire } from '@/api/deviceBridge';

function redirectLogin () {
  if (!window.Vue.pageStopped) {
  }
}

export const appSedu = {
  post (url, data, timeOut, checkLogin) {
    let redirect = true;
    if (typeof checkLogin !== 'undefined') {
      redirect = checkLogin;
    }
    if (window.debugFlag) {
      console.warn(arguments);
    }
    if (!config.requestCount["urlsedu"]) config.requestCount["urlsedu"] = 0;

    return new Promise((resolve, reject) => {
      let postData = {
        url: config.api + url,
        body: data || {},
        header: {}
      };
      AppRequire.post(postData).then(httpRequert => {
        if (window.debugFlag) {
          console.log(httpRequert);
        }
        let result = null;
        if (config.responseType == 'json') {
          //判定数据格式
          try {
            JSON.stringify(httpRequert);
            result = httpRequert;
          } catch (e) {
            config.utalkTools.logs("系统故障数据接口返回格式错误" + httpRequert, 'error');
            result = {
              errcode: 9876,
              errmsg: '数据格式错误! 请反馈给技术支持'
            };
          }
        } else {
          result = httpRequert;
        }
        resolve(result);
      }).catch(error => {
        if (config.requestCount["urlsedu"] < 3) {
          config.requestCount["urlsedu"]++;
          setTimeout(() => {
            appSedu.post(url, data).then(result => {
              config.requestCount["urlsedu"] = 0;
              resolve(result);
            }).catch(error => {
              reject(error);
            });
          }, 2000);
        } else {
          config.requestCount["urlsedu"] = 0;
          reject('网络信号不佳');
        }
      });
    });
  },
  get (url, params = {}, timeOut, checkLogin) {
    let redirect = true;
    if (typeof checkLogin !== 'undefined') {
      redirect = checkLogin;
    }
    if (window.debugFlag) {
      console.warn(arguments);
    }
    if (!config.requestCount["urlseduGet"]) config.requestCount["urlseduGet"] = 0;

    return new Promise((resolve, reject) => {
      let postData = {
        url: config.api + url + '?' + qs.stringify(params),
        body: {},
        header: {}
      };
      AppRequire.post(postData).then(httpRequert => {
        if (window.debugFlag) {
          console.log(httpRequert);
        }
        let result = null;
        if (config.responseType == 'json') {
          //判定数据格式
          try {
            JSON.stringify(httpRequert);
            result = httpRequert;
          } catch (e) {
            config.utalkTools.logs("系统故障数据接口返回格式错误" + result, 'error');
            result = {
              errcode: 9876,
              errmsg: '数据格式错误! 请反馈给技术支持'
            };
          }
          //判定数据状态
          if (result && result.errcode && result.errcode == 1004) {
            if (config.requestCount["urlseduGet"] < 3) {
              config.requestCount["urlseduGet"]++;
              return new Promise((resolve, reject) => {
                Auth.login().then(result => {
                  resolve(result);
                }).catch(error => {
                  reject('设备错误! 要不更新一下软件试试呢!');
                  config.utalkTools.logs('设备接口返回错误' + error, 'error');
                });
              }).then(result => {
                return new Promise((resolve, reject) => {
                  appSedu.get(url, params).then(result => {
                    config.requestCount["urlseduGet"] = 0;
                    resolve(result);
                  }).catch(error => {
                    reject(error);
                  });
                });
              }).catch(error => {
                reject(error);
              });
            } else {
              config.requestCount["urlseduGet"] = 0;
              config.utalkTools.logs('设备接口返回错误 无法刷新登录 获取数据', 'error');
              if (redirect) {
                redirectLogin();
              }
              reject('设备错误! 要不清一下软件缓存试试呢!');
            }
          } else if (result) {
            resolve(result);
          }
        } else {
          result = httpRequert;
        }
        resolve(result);
      }).catch(error => {
        if (config.requestCount["urlseduGet"] < 3) {
          config.requestCount["urlseduGet"]++;
          setTimeout(() => {
            appSedu.get(url, params).then(result => {
              config.requestCount["urlseduGet"] = 0;
              resolve(result);
            }).catch(error => {
              reject(error);
            });
          }, 2000);
        } else {
          config.requestCount["urlseduGet"] = 0;
          config.utalkTools.logs('apprequest 程序捕获异常' + JSON.stringify(error), 'error');
          reject(error);
        }
        // config.utalkTools.logs('apprequest 程序捕获异常' + JSON.stringify(error), 'error');
        // reject(error);
      });
    });
  }
};

import qs from "qs";
import config from "@/api/config";
import { AppRequire } from "@/api/deviceBridge";
export const appMediaCenter = {
  post(url, data, timeout) {
    if (window.debugFlag) {
      console.log(arguments);
    }
    if (!config.requestCount["mediaCenterPost"])
      config.requestCount["mediaCenterPost"] = 0;
    return new Promise((resolve, reject) => {
      let postData = {
        url: config.mediaCenter + url,
        body: data || {},
        header: {},
      };
      AppRequire.post(postData)
        .then((httpRequert) => {
          if (window.debugFlag) {
            console.log(httpRequert);
          }
          let result = null;
          if (config.responseType == "json") {
            //判定数据格式
            try {
              JSON.stringify(httpRequert);
              result = httpRequert;
            } catch (e) {
              config.utalkTools.logs(
                "系统故障数据接口返回格式错误" + result,
                "error"
              );
              result = {
                errcode: 9876,
                errmsg: "数据格式错误! 请反馈给技术支持",
              };
            }
          } else {
            result = httpRequert;
          }
          config.requestCount["mediaCenterPost"] = 0;
          resolve(result);
        })
        .catch((err) => {
          if (config.requestCount["mediaCenterPost"] < 3) {
            config.requestCount["mediaCenterPost"]++;
            this.post(url, data, timeout);
          } else {
            config.requestCount["mediaCenterPost"] = 0;
            reject(err);
          }
        });
    });
  },
  get(url, params = {}, timeout) {
    if (window.debugFlag) {
      console.log(arguments);
    }
    if (!config.requestCount["mediaCenterGet"])
      config.requestCount["mediaCenterGet"] = 0;
    return new Promise((resolve, reject) => {
      let postData = {
        url: `${config.mediaCenter}${url}?${qs.stringify(params)}`,
        body: {},
        header: {},
      };
      AppRequire.post(postData)
        .then((httpRequert) => {
          if (window.debugFlag) {
            console.log(httpRequert);
          }
          let result = null;
          if (config.responseType == "json") {
            //判定数据格式
            try {
              JSON.stringify(httpRequert);
              result = httpRequert;
            } catch (e) {
              config.utalkTools.logs(
                "系统故障数据接口返回格式错误" + result,
                "error"
              );
              result = {
                errcode: 9876,
                errmsg: "数据格式错误! 请反馈给技术支持",
              };
            }
          } else {
            result = httpRequert;
          }
          config.requestCount["mediaCenterGet"] = 0;
          resolve(result);
        })
        .catch((err) => {
          if (config.requestCount["mediaCenterGet"] < 3) {
            config.requestCount["mediaCenterGet"]++;
            this.get(url, params, timeout);
          } else {
            config.requestCount["mediaCenterGet"] = 0;
            reject(err);
          }
        });
    });
  },
};

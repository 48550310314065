!function (window) {

  //设计图文档宽度 
  const docWidth = 375;

  const doc = window.document,
    docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';

  const recalc = (function refreshRem () {
    let clientWidth = docEl.getBoundingClientRect().width;
    //1.86:700 最大放大值   最小值 0.85:320
    let fontSize = Math.max(Math.min((clientWidth / docWidth), 1.6), 0.85) * 18 + 'px';
    if (clientWidth >= 481 && clientWidth < 768) {
      fontSize = Math.max(Math.min((clientWidth / docWidth), 1.6), 0.85) * 11 + 'px';
    } else if (clientWidth >= 768) {
      fontSize = Math.max(Math.min((clientWidth / docWidth), 1.6), 0.85) * 15 + 'px';
    } else {
      fontSize = Math.max(Math.min((clientWidth / docWidth), 1.6), 0.85) * 18 + 'px';
    }
    docEl.style.fontSize = fontSize;
    window.document.querySelector('body').style.fontSize = fontSize;
    return refreshRem;
  })();

  /* 添加倍屏标识，安卓为1 */
  docEl.setAttribute('data-dpr', window.devicePixelRatio);

  if (/iP(hone|od|ad)/.test(window.navigator.userAgent)) {
    /* 添加IOS标识 */
    doc.documentElement.classList.add('ios');
    /* IOS8以上给html添加hairline样式，以便特殊处理 */
    if (parseInt(window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10) >= 8)
      doc.documentElement.classList.add('hairline');
  }

  if (!doc.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);

}(window);

import { profile } from './interface/profile.js';
import { lesson } from './interface/lesson.js';
import { homework } from './interface/homework.js';
import { home } from './interface/home.js';
import { teacher } from './interface/teacher.js';
import { booking } from './interface/booking.js';
import { login } from './interface/login.js';
import { advertisement } from './interface/advertisement.js';
import { preview } from './interface/preview.js';
import { myself } from './interface/myself.js';
import { toc_booking } from './interface/toc_booking.js';
import { offlineLesson } from './interface/offlineLesson.js';
import { address_book } from './interface/address_book.js';
import { privilege } from './interface/privilege.js';
import { ai } from './interface/ai.js';
import { material } from './interface/material.js';
import { notify } from './interface/notify.js';
import {infoList} from './interface/infoList.js';

export const Profile = profile;
export const Lesson = lesson;
export const Homework = homework;
export const Home = home;
export const Teacher = teacher;
export const Booking = booking;
export const Login = login;
export const Advertisement = advertisement;
export const Preview = preview;
export const Myself = myself;
export const TOC_Booking = toc_booking;
export const OfflineLesson = offlineLesson;
export const AddressBook = address_book;
export const Privilege = privilege;
export const Ai = ai;
export const Material = material;
export const Notify = notify;
export const InfoList=infoList;


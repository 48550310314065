<style scoped>
.empty-booking-data {
  color: #757575;
  text-align: center;
  height: 100vh;
  width: 100%;
  background-size: 98% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.empty-booking-data-utalk {
  color: #757575;
  font-size: 0.9rem;
  text-align: center;
  height: 100vh;
  width: 100%;
}
.utalk {
  width: 11rem;
  margin-top: 50%;
  margin-bottom: 30px;
}
.title {
  margin-top: 30px;
  font-size: 14px;
}
</style>
<template>
  <div>
    <div
      v-show="!isUtalk && screenColor === 'LIGHT'"
      class="empty-booking-data"
      :style="{ backgroundImage: 'url(\'' + backgroundEmpty + '\')' }"
    ></div>
    <div
      v-show="!isUtalk && screenColor === 'DARK'"
      class="empty-booking-data"
      :style="{ backgroundImage: 'url(\'' + backgroundEmptyDark + '\')' }"
    ></div>
    <!-- <div v-show="isUtalk" class="empty-booking-data-utalk">
      <img class="utalk" :src="backgroundEmptyUtalk" alt="" />
      <div class="title">暂无课程信息</div>
    </div> -->

    <div
      v-show="isUtalk && screenColor == 'LIGHT'"
      class="empty-booking-data-utalk"
    >
      <img class="utalk" :src="backgroundEmptyUtalk" alt="" />
      <div>很抱歉，目前没有可预约的课程</div>
    </div>
    <div
      v-show="isUtalk && screenColor == 'DARK'"
      class="empty-booking-data-utalk"
    >
      <img class="utalk" :src="backgroundEmptyUtalkDark" alt="" />
      <div>很抱歉，目前没有可预约的课程</div>
    </div>
  </div>
</template>

<script>
import { siteEnv } from '@/utils/tools';

export default {
  name: 'No_Booking_Data',
  data() {
    return {
      isUtalk: siteEnv() == 'utalk',
      backgroundEmpty: require('@/assets/imgs/common/joyNoBook.jpg'),
      backgroundEmptyDark: require('@/assets/imgs/common/joyNoBook.png'),
      backgroundEmptyUtalk: require('@/assets/imgs/common/utalkNoTime.png'),
      backgroundEmptyUtalkDark: require('@/assets/imgs/common/utalkNoTime_dark.png'),
    };
  },
  computed: {
    screenColor() {
      return this.$store.getters.getScreenTheme;
    },
  },
};
</script>

import quick from '../quick.js';
export const pay = {
  alipay (item) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'payAli',
        mudule: 'pay',
        isLongCb: 0,
        data: item
      };
      quick.callApi(postData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  wechatPay (item) {
    return new Promise((resolve, reject) => {
      let postData = {
        name: 'payWx',
        mudule: 'pay',
        isLongCb: 0,
        data: item
      };
      quick.callApi(postData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  }
};
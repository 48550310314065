import quick from '../quick';

//登录
export const class_in = {
  joinClassRoom (data) {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'joinClassRoom',
        mudule: 'classIn',
        isLongCb: 0,
        data: data || {}
      };
      quick.callApi(sendData).then(result => {
		  console.log(result,"class_in")
        resolve(result);
      }).catch(error => {
		  console.log(error,"class_in")
        reject(error.msg);
      });
    });
  }

};

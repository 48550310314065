import { appRequire } from './bridgeInterface/appRequire';
import { auth } from './bridgeInterface/auth.js';
import { homework } from './bridgeInterface/homework.js';
import { page } from './bridgeInterface/page.js';
import { runtimeApi } from './bridgeInterface/runtime.js';
import { cacheAPI } from './bridgeInterface/cache.js';
import { general } from './bridgeInterface/general.js';
import { pay } from './bridgeInterface/pay.js';
import { mediaPannel } from './bridgeInterface/material.js';
import { mediaDownload } from './bridgeInterface/materialDownload.js';
import { push } from './bridgeInterface/push.js';
import { upload } from './bridgeInterface/upload.js';
import { media } from './bridgeInterface/media.js';
import { privacy } from './bridgeInterface/privacy';
import { class_in } from './bridgeInterface/classin.js';

export const AppRequire = appRequire;
export const Auth = auth;
export const Classin = class_in;
export const Studentwork = homework;
export const Page = page;
export const Runtime = runtimeApi;
export const Cache = cacheAPI;
export const General = general;
export const Pay = pay;
export const Material_media = mediaPannel;
export const Material_download = mediaDownload;
export const Push = push;
export const Upload = upload;
export const Media = media;
export const Privacy = privacy;

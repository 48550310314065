import qs from 'qs';
import config from '@/api/config';
import { Page, Auth, AppRequire } from '@/api/deviceBridge';

function redirectLogin () {
  if (!window.Vue_ai_lesson.pageStopped) {
    let url = config.hybridJoyServer + '/login/';
    let h5appid = config.newHybridAppId;
    let h5apppath = 'login';
    let h5parameter = '#/';
    let h5appindex = 'index.html';
    Page.openWebView(url, '', false, '', '', '', h5appid, h5apppath, h5parameter, h5appindex);
  }
}

export const appAi = {
  post (url, data, timeOut, checkLogin) {
    let redirect = true;
    if (typeof checkLogin !== 'undefined') {
      redirect = checkLogin;
    }
    if (window.debugFlag) {
      console.log(arguments);
    }
    if (!config.requestCount["urlAi"]) config.requestCount["urlAi"] = 0;

    return new Promise((resolve, reject) => {
      Auth.getAccount().then(result => {
        if (!result) {
          if (redirect) {
            redirectLogin();
          }
          config.requestCount["urlAi"] = 0;
          reject('设备错误! 要不更新一下软件试试呢!');
        }
      });

      let postData = {
        url: config.aiLesson + url,
        body: data || {},
        header: {}
      };
      AppRequire.post(postData).then(httpRequert => {
        if (window.debugFlag) {
          console.log(httpRequert);
        }
        let result = null;
        if (config.responseType == 'json') {
          //判定数据格式
          try {
            JSON.stringify(httpRequert);
            result = httpRequert;
          } catch (e) {
            config.utalkTools.logs("系统故障数据接口返回格式错误" + httpRequert, 'error');
            result = {
              errcode: 9876,
              errmsg: '数据格式错误! 请反馈给技术支持'
            };
          }
          //判定数据状态
          if (result && result.errcode && result.errcode == 1004) {
            if (config.requestCount["urlAi"] < 3) {
              config.requestCount["urlAi"]++;
              return new Promise((resolve, reject) => {
                Auth.getAccount().then(result => {
                  window.Vue_ai_lesson.$store.commit('saveLogin', result);
                  resolve(true);
                }).catch(error => {
                  reject('设备错误! 要不更新一下软件试试呢!');
                  config.utalkTools.logs('设备接口返回错误' + error, 'error');
                });
              }).then(result => {
                return new Promise((resolve, reject) => {
                  appAi.post(url, data).then(result => {
                    config.requestCount["urlAi"] = 0;
                    resolve(result);
                  }).catch(error => {
                    reject(error);
                  });
                });
              }).catch(error => {
                reject(error);
              });
            } else {
              config.requestCount["urlAi"] = 0;
              config.utalkTools.logs('设备接口返回错误 无法刷新登录 获取数据', 'error');
              reject('设备错误! 要不清一下软件缓存试试呢!');
            }
          } else if (result) {
            config.requestCount["urlAi"] = 0;
            resolve(result);
          }
        } else {
          result = httpRequert;
        }
        resolve(result);
      }).catch(error => {
        let httpcode;
        if (error && JSON.parse(error) && JSON.parse(error).httpcode) { httpcode = JSON.parse(error).httpcode; }
        if (httpcode == 500) {
          reject('网络信号不佳');
        } else {
          if (config.requestCount["urlAi"] < 3) {
            config.requestCount["urlAi"]++;
            setTimeout(() => {
              appAi.post(url, data).then(result => {
                config.requestCount["urlAi"] = 0;
                resolve(result);
              }).catch(error => {
                reject(error);
              });
            }, 2000);
          } else {
            config.requestCount["urlAi"] = 0;
            reject('网络信号不佳');
          }
        }
      });
    });
  },
  get (url, params = {}, timeOut, checkLogin) {
    let redirect = true;
    if (typeof checkLogin !== 'undefined') {
      redirect = checkLogin;
    }
    if (window.debugFlag) {
      console.log(arguments);
    }
    if (!config.requestCount["urlAiGet"]) config.requestCount["urlAiGet"] = 0;
    return new Promise((resolve, reject) => {
      Auth.getAccount().then(result => {
        if (!result) {
          if (redirect) {
            redirectLogin();
          }
          config.requestCount["urlAiGet"] = 0;
          reject('设备错误! 要不更新一下软件试试呢!');
        }
      });
      let postData = {
        url: config.aiLesson + url + '?' + qs.stringify(params),
        body: {},
        header: {}
      };
      AppRequire.post(postData).then(httpRequert => {
        if (window.debugFlag) {
          console.log(httpRequert);
        }
        let result = null;
        if (config.responseType == 'json') {
          //判定数据格式
          try {
            JSON.stringify(httpRequert);
            result = httpRequert;
          } catch (e) {
            config.utalkTools.logs("系统故障数据接口返回格式错误" + result, 'error');
            result = {
              errcode: 9876,
              errmsg: '数据格式错误! 请反馈给技术支持'
            };
          }
          //判定数据状态
          if (result && result.errcode && result.errcode == 1004) {
            if (config.requestCount["urlAiGet"] < 3) {
              config.requestCount["urlAiGet"]++;
              return new Promise((resolve, reject) => {
                Auth.getAccount().then(result => {
                  window.Vue_ai_lesson.$store.commit('saveLogin', result);
                  resolve(true);
                }).catch(error => {
                  reject('设备错误! 要不更新一下软件试试呢!');
                  config.utalkTools.logs('设备接口返回错误' + error, 'error');
                });
              }).then(result => {
                return new Promise((resolve, reject) => {
                  appAi.get(url, params).then(result => {
                    config.requestCount["urlAiGet"] = 0;
                    resolve(result);
                  }).catch(error => {
                    reject(error);
                  });
                });
              }).catch(error => {
                reject(error);
              });
            } else {
              config.requestCount["urlAiGet"] = 0;
              config.utalkTools.logs('设备接口返回错误 无法刷新登录 获取数据', 'error');
              reject('设备错误! 要不清一下软件缓存试试呢!');
            }
          } else if (result) {
            resolve(result);
          }
        } else {
          result = httpRequert;
        }
        resolve(result);
      }).catch(error => {
        if (config.requestCount["urlAiGet"] < 3) {
          config.requestCount["urlAiGet"]++;
          setTimeout(() => {
            appAi.get(url, params).then(result => {
              config.requestCount["urlAiGet"] = 0;
              resolve(result);
            }).catch(error => {
              reject(error);
            });
          }, 2000);
        } else {
          config.requestCount["urlAiGet"] = 0;
          config.utalkTools.logs('apprequest 程序捕获异常' + JSON.stringify(error), 'error');
          reject(error);
        }
        // config.utalkTools.logs('apprequest 程序捕获异常' + JSON.stringify(error), 'error');
        // reject(error);
      });
    });
  }
};
// import { Auth } from '@/api/deviceBridge.js';
import quick from '@/api/quick.js';

//http 请求
import { joy } from '@/api/axios/joy.js';
import { sedu } from '@/api/axios/sedu.js';
import { pay } from '@/api/axios/pay.js';
import { link } from '@/api/axios/link.js';
import { ai } from '@/api/axios/ai.js';
import { dataCenter } from '@/api/axios/dataCenter.js';
import { mediaCenter } from '@/api/axios/mediaCenter.js';

//APP 封装请求
import { appJoy } from '@/api/appRequest/joy.js';
import { appSedu } from '@/api/appRequest/sedu.js';
import { appPay } from '@/api/appRequest/pay.js';
import { appLink } from '@/api/appRequest/appLink.js';
import { appAi } from '@/api/appRequest/ai.js';
import { appDataCenter } from '@/api/appRequest/dataCenter.js';
import { appMediaCenter } from '@/api/appRequest/appMediaCenter.js';

export const http = !quick.os.h5 ? appSedu : sedu;
export const Joyhttp = !quick.os.h5 ? appJoy : joy;
export const payHttp = !quick.os.h5 ? appPay : pay;
export const linkHttp = !quick.os.h5 ? appLink : link;
export const aiHttp = !quick.os.h5 ? appAi : ai;
export const dataCenterHttp = !quick.os.h5 ? appDataCenter : dataCenter;

export const reHttpSedu = sedu;
export const reHttpjoy = joy;

export const mediaCenterHttp = !quick.os.h5 ? appMediaCenter : mediaCenter;

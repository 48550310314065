import qs from "qs";
import config from "@/api/config";
import axios from "@/api/axios/head";

export const mediaCenter = {
  post(url, data, timeOut) {
    if (window.debugFlag) {
      console.warn(arguments);
    }
    return axios({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
      },
      methods: "post",
      url: config.mediaCenter + url,
      data: data,
      timeout: timeOut || config.timeout,
      responseType: config.responseType,
      transformRequest: [
        function(data) {
          return qs.stringify(data);
        },
      ],
      transformResponse: [
        function(data) {
			try{
				if ( typeof( data ) == "string" ) {
					data = JSON.parse(data)
				}
			}catch(e){}
          if (
            data &&
            data.code &&
            data.code != "200" &&
            typeof data.errmsg == "string"
          ) {
            console.log(
              `%cERRCODE: + ${data.code}:ERRMSG\n${data.msg}`,
              "color: #ffffff;background-color: #DC143C"
            );
          }
          return data;
        },
      ],
    })
      .then((res) => {
        if (res && res.code) {
          return Promise.reject(res);
        } else {
          return Promise.resolve(res);
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  get(url, params = {}, timeOut) {
    if (window.debugFlag) {
      console.warn(arguments);
    }
    return axios({
      method: "get",
      url: config.mediaCenter + url,
      params,
      timeout: timeOut || config.timeout,
      responseType: config.responseType,
      transformResponse: [
        function(data) {
			try{
				if ( typeof( data ) == "string" ) {
					data = JSON.parse(data)
				}
			}catch(e){}
          if (
            data &&
            data.code &&
            data.code != "200" &&
            typeof data.errmsg == "string"
          ) {
            console.log(
              `%cERRCODE: + ${data.code}:ERRMSG\n${data.msg}`,
              "color: #ffffff;background-color: #DC143C"
            );
          }
          return data;
        },
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
};

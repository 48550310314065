import quick from '../quick.js'
import config from '@/api/config'
import { linkHttp } from '../fetch.js'
export const runtimeApi = {
    getHybridVersion(){
        console.log("home hybrid version")
        return new Promise((resolve, reject) => {
            let sendData = {
                name: 'getHybridVersion', 
                mudule: 'runtime', 
                isLongCb: 0, 
                data: {}
            } 
            quick.callApi(sendData).then(result=>{
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        });
        
    },
    getAppInfo(){
        return new Promise((resolve, reject) => {
            let sendData = {
                name: 'getAppInfo', 
                mudule: 'runtime',
            } 
            quick.callApi(sendData).then(result=>{
                resolve(result);
            }).catch(error=>{  
                reject(error.msg);
            }); 
        });
    },
    hybridTestResult(postData){
        return new Promise((resolve, reject) => {
            linkHttp.get(config.deviceTestResult, postData)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject( error )
                })
        });
    },
}
import qs from 'qs'
import config from '@/api/config'
import axios from '@/api/axios/head'
import {Auth}  from '@/api/deviceBridge'
import { profile } from '@/api/interface/profile'

function checkSession () {
	return new Promise((resolve, reject) => {
		profile.check_session()
			.then(result => resolve(result))
			.catch(error => { 
				localStorage.clear();
				document.cookie = ''
				window.Vue.$store.commit('saveLogin', {}) ;
				window.Vue.$router.push('/login') ;
				window.Vue.$session.destroy() ;
				reject(error)
			})
	})
}
export const sedu = {
	post(url, data, timeOut) {
		if ( window.debugFlag ) {
            console.warn(arguments)
	  	} 
        return axios({
            method: 'post',
            url: config.api + url,
            data: data,
            timeout: timeOut || config.timeout,
			responseType: config.responseType,
			transformRequest: [function (data) {
			    return qs.stringify(data);
			}],
			transformResponse: [function (data) {
				
				try{
					if ( typeof( data ) == "string" ) {
						data = JSON.parse(data)
					}
				}catch(e){}
				
				if ( data && data.errcode && data.errcode != '200' && typeof data.errmsg == 'string' ) {
					//config.utalkTools.logs('用户操作警告' + data.errmsg, 'warning');
				}
				if (config.responseType == 'json') {
					if ( ! data ) { 
						config.utalkTools.logs('系统故障返回数据非JSON格式', 'error');
						return {
							errcode:9876,
							errmsg:'数据错误! 请反馈给技术支持'
						};
					}
				}
				return data;
			}],
            headers: {
				'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
				'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(result=>{
			if ( result && result.errcode && result.errcode == 1004 ) { 
				// if ( config.requestCount < 3 ) {
					// config.requestCount++;  
					/** return new Promise((resolve, reject) => {
			            Auth.login().then(result=>{  
			                resolve(result);
			            }).catch(error=>{  
							reject('设备错误! 要不更新一下软件试试呢!');
			                config.utalkTools.logs('设备接口返回错误' + error, 'error');
			            });
					}).then(result=>{
						return new Promise((resolve, reject) => {
							sedu.post(url, data).then(result=>{ 
								config.requestCount=0;  
								resolve(result);
							}).catch(error=>{   
								reject(error);
							});
						});
					}).catch(error=>{
						reject(error);
					})*/
					
					// return checkSession().then(()=>{
					// 	return new Promise((resolve, reject) => {
					// 		sedu.post(url, data).then(result=>{ 
					// 			config.requestCount=0;  
					// 			resolve(result);
					// 		}).catch(error=>{   
					// 			reject(error);
					// 		});
					// 	});
					// }).catch(error=> reject(error))
				// } else {
					// config.requestCount=0;  
					//config.utalkTools.logs('设备接口返回错误 无法刷新登录 获取数据', 'error');
					return Promise.reject('设备错误! 要不清一下软件缓存试试呢!');
				// }	
			} else if ( result ) {
				return Promise.resolve(result);
			}
		}).catch(error=>{
			return Promise.reject(error);
		});
    },
    get(url, params = {}, timeOut) {
		if ( window.debugFlag ) {
            console.warn(arguments)
	  	} 
        return axios({
            method: 'get',
            url: config.api + url,
            params,
            timeout: timeOut || config.timeout,
			responseType: config.responseType,
			transformResponse: [function (data) {
				try{
					if ( typeof( data ) == "string" ) {
						data = JSON.parse(data)
					}
				}catch(e){}
				//console.log( ">>>>>>>>>>>>>", data )
				if ( data && data.errcode && data.errcode != '200' && typeof data.errmsg == 'string' ) {
					//config.utalkTools.logs('用户操作警告' + data.errmsg, 'warning');
				}
				if (config.responseType == 'json') {
					if ( ! data ) { 
						config.utalkTools.logs('系统故障错误空数据返回', 'error');
						return {
							errcode:9876,
							errmsg:'数据格式错误! 请反馈给技术支持'
						};
					}
				}
				return data;
			}],
            headers: {
				'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
				'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(result=>{
			if ( result && result.errcode && result.errcode == 1004 ) {  
				//if ( config.requestCount < 3 ) {
				//	config.requestCount++;  
					// return new Promise((resolve, reject) => {
					// 	let sendData = {
			  //               name: 'login', 
			  //               mudule: 'auth', 
			  //               isLongCb: 0, 
			  //               data: {}
			  //           }
			  //           Auth.login().then(result=>{  
			  //               //resolve(result);
					// 		return new Promise((resolve, reject) => {
					// 			sedu.get(url, params).then(result=>{ 
					// 				//config.requestCount=0;  
					// 				resolve(result);
					// 			}).catch(error=>{   
					// 				reject(error);
					// 			});
					// 		});
			  //           }).catch(error=>{  
					// 		reject('设备错误! 要不更新一下软件试试呢!');
			  //               config.utalkTools.logs('设备接口返回错误' + error, 'error');
			  //           });
					// })
					//return checkSession().then(()=>{
						// return new Promise((resolve, reject) => {
						// 	sedu.get(url, params).then(result=>{ 
						// 		config.requestCount=0;  
						// 		resolve(result);
						// 	}).catch(error=>{   
						// 		reject(error);
						// 	});
						// });
					//}).catch(error => reject(error))
				//} else {
				//	config.requestCount=0;  
				//	config.utalkTools.logs('设备接口返回错误 无法刷新登录 获取数据', 'error');
				//	return Promise.reject('设备错误! 要不清一下软件缓存试试呢!');
				//}
			} else if ( result ) {
				return Promise.resolve(result);
			}
		}).catch(error=>{
			return Promise.reject(error);
		});
    }
}

export default {
	install (Vue, options) {
		let openDelay = false;
		Vue.directive('intervalclick', function (el, binding) {
			el.onclick = function (e) {
				if (openDelay) return;
				openDelay = !openDelay;
				if (!binding.value) {
					return;
				}
				let func = binding.value['func'];
				let time = binding.value['time'];
				if (typeof time !== 'number') {
					return;
				}
				let args = [];
				for (const key in binding.value) {
					if (binding.value.hasOwnProperty(key)) {
						if (key === 'func' || key === 'time') continue;
						args.push(binding.value[key])
					}
				}
				setTimeout(() => {
					openDelay = !openDelay;
				}, time);
				console.log(args);
				func(...args);
			}
		})
	}
};


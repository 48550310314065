import { aiHttp } from '../fetch.js'

export const ai = {
  // 获取课程包、待上课程、已上课程
  getLessonGrade (postData) {
    return new Promise((resolve, reject) => {
      aiHttp.post(
        '/api/Lesson/getLessonGrade',
        postData
      ).then(result => {
        if (result) {
          if (result.code != 200) {
            reject(result.message);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 进入教室
  goToCLassroom (postData) {
    return new Promise((resolve, reject) => {
      aiHttp.post(
        '/Tools/startLesson',
        postData
      ).then(result => {
        if (result) {
          if (result.code != 200) {
            reject(result.message);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  beginRoom(postData = {}) {
    return new Promise((resolve, reject) => {
      aiHttp.post(
        '/Lesson/begin',
        postData
      ).then(result => {
        if (result) {
          if (result.code != 200) {
            reject(result.message);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  }
}

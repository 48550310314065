import quick from '../quick.js';
import { siteEnvColor } from '@/utils/tools';
import { Auth } from '@/api/deviceBridge';
import config from '@/api/config';

export const page = {
  
  openAiRoom(data) {
    return new Promise((resolve, reject) => {
      if (!/QuickHybridJs/.test(window.navigator.appVersion)) {
        reject("非App环境")
      } else {
        let sendData = {
            name: 'openAiRoom',
            mudule: 'page',
            isLongCb: 0,
            data: data || {},
            success(result) {
                resolve(result);
            },
            error(error) {
                reject(error);
            }
        };
        quick.callApi(sendData).then(result => {
          resolve(result);
        }).catch(error => {
          reject(error.msg);
        });
      }
    })
  },
  
  aiBegin(data) {
    return new Promise((resolve, reject) => {
      if (!/QuickHybridJs/.test(window.navigator.appVersion)) {
        reject("非App环境")
      } else {
        let sendData = {
            name: 'aiBegin',
            mudule: 'page',
            isLongCb: 0,
            data: data || {},
            success(result) {
                resolve(result);
            },
            error(error) {
                reject(error);
            }
        };
        quick.callApi(sendData).then(result => {
          resolve(result);
        }).catch(error => {
          reject(error.msg);
        });
      }
    })
  },
  
  openWebViewCustom (data) {
    let swipeBack = data.swipeBack;
    if (!swipeBack === false) {
      swipeBack = true;
    }

    let sendData = {
      name: 'openWebView',
      mudule: 'page',
      isLongCb: 0,
      data: {
        url: data.url,
        swipeBack: swipeBack,
        title: data.title || '',
        fullScreen: data.fullScreen || false,
        canGoBack: data.canGoBack || false,
        hybrid: data.hybrid || true,
        screen: data.screen || 'portrait',
        hideTitleBar: data.hideTitleBar || false,
        h5parameter: data.h5parameter || '#/',
        h5appid: data.h5appid || '',
        h5apppath: data.h5apppath || '',
        h5appindex: data.h5appindex || 'index.html'
      }
    };

    return Auth.getAccount().then(result => {
      if (!result) {
        sendData.data.url = config.hybridJoyServer + '/login/index.html#/';
        sendData.data.title = '';
        sendData.data.h5apppath = 'login';
        sendData.data.h5parameter = '#/';
        sendData.data.canGoBack = false;

        this.proceedOpenWebView(sendData)
          .then(r => {
            return r;
          });
      } else {
        this.proceedOpenWebView(sendData)
          .then(r => {
            return r;
          });
      }
    });
  },
  openWebView (url, title, canGoBack, hybrid, screen, hideTitleBar, h5appid, h5apppath, h5appindex, h5parameter, requestLogin = true, swipeBack = true, fullScreen, anim, disableBack) {
    let sendData = {
      name: 'openWebView',
      mudule: 'page',
      isLongCb: 0,
      data: {
        url: url,
        title: title || '',
        titlebarColor: siteEnvColor(),
        canGoBack: canGoBack || false,
        hybrid: hybrid || true,
        screen: screen || 'portrait',
        hideTitleBar: hideTitleBar || false,
        swipeBack: swipeBack,
        h5appid: h5appid || '',
        h5apppath: h5apppath || '',
        h5appindex: h5appindex || 'index.html',
        h5parameter: h5parameter || '#/',
        fullScreen: fullScreen || false,
        anim: anim || 'none',
        disableBack: disableBack || false
      }
    };
    return Auth.getAccount().then(result => {

      if (!result && requestLogin) {
        sendData.data.url = config.hybridJoyServer + '/login/index.html#/';
        sendData.data.title = '';
        sendData.data.h5apppath = 'login';
        sendData.data.h5parameter = '#/';
        sendData.data.hideTitleBar = false;
        sendData.data.canGoBack = false;

        this.proceedOpenWebView(sendData)
          .then(r => {
            return r;
          });
      } else {
        this.proceedOpenWebView(sendData)
          .then(r => {
            return r;
          });
      }
    });
  },
  proceedOpenWebView (sendData) {
    return new Promise((resolve, reject) => {
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  openWebViewCustomData (data) {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'openWebView',
        mudule: 'page',
        isLongCb: 0,
        data: data
      };

      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  exitWebView (exitData) {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'exitWebView',
        mudule: 'page',
        isLongCb: 0,
        data: exitData || {}
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  openNewPage (pageCode, title, lessonObject) {
    title = title || '';
    lessonObject = lessonObject || {};
    let postData = { pageCode: pageCode, title: title, titlebarColor: siteEnvColor() };
    // if (pageCode == 'app_ui_showing_ppt' || pageCode == 'app_ui_wrong_topic_book')
    postData.data = lessonObject;
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'openNewPage',
        mudule: 'page',
        isLongCb: 0,
        data: postData
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  setTabSelect (tab, callBack, callData) {
    let postData = { index: tab || 0, callBack: callBack || '', callData: callData || '' };
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'setTabSelect',
        mudule: 'home',
        isLongCb: 0,
        data: postData
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  killAllSecondWebView () {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'killAllSecondWebView',
        mudule: 'home',
        isLongCb: 0,
        data: {}
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  setTitle (title) {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'setTitle',
        mudule: 'page',
        isLongCb: 0,
        data: title || {}
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  openVideo (url, screen, title, hideTitleBar, fullScreen, enable, loop) {
    let postData = {
      url: url,
      screen: screen || 'landscape',
      title: title || '',
      hideTitleBar: hideTitleBar || false,
      fullScreen: fullScreen || 'none',
      enable: enable || 'enable',
      loop: loop || 'none'
    };
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'openVideoPanel',
        mudule: 'page',
        isLongCb: 0,
        data: postData
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  },
  closeApp () {
    return new Promise((resolve, reject) => {
      let sendData = {
        name: 'closeApp',
        mudule: 'page',
        isLongCb: 0,
        data: {}
      };
      quick.callApi(sendData).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error.msg);
      });
    });
  }
};

<style scoped>
.empty-booking-data {
  color: #757575;
  text-align: center;
  height: 95vh;
  width: 100%;
  background-size: 98% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.empty-booking-data-utalk {
  color: #757575;
  font-size: 0.9rem;
  text-align: center;
  height: 100vh;
  width: 100%;
}
.utalk {
  width: 11rem;
  margin-top: 40%;
  margin-bottom: 30px;
}
.buyClass {
  background: rgb(28, 118, 253);
  color: #ffffff;
  border: none;
  padding: 0.5rem 1.2rem;
  border-radius: 20px;
  margin-top: 30px;
}
</style>
<template>
  <div>
    <div
      v-show="!isUtalk"
      class="empty-booking-data"
      :style="{ backgroundImage: 'url(\'' + backgroundEmpty + '\')' }"
    ></div>
    <div
      v-show="isUtalk && screenColor == 'LIGHT'"
      class="empty-booking-data-utalk"
    >
      <img class="utalk" :src="backgroundEmptyUtalk" alt="" />
      <div>很抱歉，您的课时不足，请先购买课时</div>
      <button class="buyClass" @click="buyClass">去购课</button>
    </div>
    <div
      v-show="isUtalk && screenColor == 'DARK'"
      class="empty-booking-data-utalk"
    >
      <img class="utalk" :src="backgroundEmptyUtalkDark" alt="" />
      <div>很抱歉，您的课时不足，请先购买课时</div>
      <button class="buyClass" @click="buyClass">去购课</button>
    </div>
  </div>
</template>

<script>
import { siteEnv } from '@/utils/tools';
import { Page } from '@/api/deviceBridge';
import config from '@/api/config';

export default {
  name: 'No_Lesson_Points',
  data() {
    return {
      isUtalk: siteEnv() == 'utalk',
      backgroundEmpty: require('@/assets/imgs/common/no_lesson_points.png'),
      backgroundEmptyUtalk: require('@/assets/imgs/common/utalkNoTime.png'),
      backgroundEmptyUtalkDark: require('@/assets/imgs/common/utalkNoTime_dark.png'),
    };
  },
  computed: {
    screenColor() {
      return this.$store.getters.getScreenTheme;
    },
  },
  methods: {
    buyClass() {
      if (Page.openWebView) {
        let title = '在线购课';
        let url = config.hybridJoyServer + '/profile/index.html#/Home/Privilege';
        let h5appid = config.newHybridAppId;
        let h5apppath = 'profile';
        let h5appindex = 'index.html';
        let h5parameter = '#/Home/Privilege';
        Page.openWebView(
          url,
          title,
          '',
          '',
          '',
          '',
          h5appid,
          h5parameter,
          h5appindex,
          h5apppath
        )
          .then((result) => {})
          .catch((error) => {})
          .finally((result) => {});
      }
    },
  },
};
</script>

<style lang="scss">
.footer_menu {
  padding: 0 1rem;
  margin: 0;
  display: inline-flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  border-top: solid 1px #f5f5f5;
  box-shadow: 1px 0 5px #f5f5f5;

  li {
    width: 100%;
    font-size: 0.5rem;
    text-align: center;
    padding: 0.5rem 0;

    img {
      width: 25%;
    }

    span {
      display: inline-block;
      width: 100%;
      color: #999;
    }

    &.active_index {
      span {
        color: #f74b49;
      }
    }
  }
}
.dark {
  .footer_menu {
    background: #2b2f36 !important;
    border-top: 1px solid #394147 !important;
    box-shadow: 1px 0 5px #2b2f36 !important;
  }
}
</style>
<template>
  <ul class="footer_menu">
    <li
      v-for="(menu, index) in menuList.list"
      :class="{ active_index: menu.active }"
      @click="setActiveMenu(index)"
      :key="index"
    >
      <img :src="menu.active ? menu.imgActive : menu.imgDefault" />
      <span>{{ menu.name }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'tabbar',
  props: {
    flag: {
      default: '',
      type: String,
    },
    homeFlag: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      menuList: {
        current_index: 1,
        list: [
          {
            active: false,
            name: '首页',
            imgDefault: require('@/assets/imgs/common/icon1.png'),
            imgActive: require('@/assets/imgs/common/icon1_active.png'),
            path: 'Home',
          },
          {
            active: false,
            name: '线上外教课',
            imgDefault: require('@/assets/imgs/common/icon2.png'),
            imgActive: require('@/assets/imgs/common/icon2_active.png'),
            path: 'Lesson',
          },
          {
            active: false,
            name: '智能外教课',
            imgDefault: require('@/assets/imgs/common/icon3.png'),
            imgActive: require('@/assets/imgs/common/icon3_active.png'),
            path: 'AiLesson',
          },
          {
            active: false,
            name: '我的',
            imgDefault: require('@/assets/imgs/common/icon5.png'),
            imgActive: require('@/assets/imgs/common/icon5_active.png'),
            path: 'Myself',
          },
        ],
      },
    };
  },
  methods: {
    setActiveMenu(idx) {
      this.menuList.current_index = idx;
      this.menuList.list[idx].active = true;
      if (
        (this.$router.options.base == '/home/' && idx == 0) ||
        (this.$router.options.base == '/class_list/' && idx == 1) ||
        (this.$router.options.base == '/ai_lesson/' && idx == 2) ||
        (this.$router.options.base == '/profile/' && idx == 3)
      ) {
        return;
      }
      if (this.menuList.list[idx].path != '') {
        this.$router.push({ name: this.menuList.list[idx].path });
      }
    },
  },
  mounted() {
    let menuIndex = 0;
    if (this.$route.meta && this.$route.meta.menuIndex) {
      menuIndex = this.$route.meta.menuIndex;
    }
    this.setActiveMenu(menuIndex);
  },
};
</script>

<template>
  <div
    class="page_splash_loader"
    :style="{ position: position, height: height }"
  >
    <div class="floating_div">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="loading-text" style="font-size: .7rem">正在加载...</div>
    </div>
  </div>
</template>
<style lang="scss">
.page_splash_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  //background: #fff !important;
  background-color: transparent !important;
  z-index: 10000;
}
.page_splash_loader .floating_div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.page_splash_loader .lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.page_splash_loader .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 6px;
  border: 5px solid $primarycolor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primarycolor transparent transparent transparent;
}
.page_splash_loader .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.page_splash_loader .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.page_splash_loader .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<script>
export default {
  name: 'Loading',
  data() {
    return {
      loadingLayerColor: '#AAA',
    };
  },
  props: {
    position: {
      default: 'fixed',
      type: String,
    },
    height: {
      default: '100%',
      type: String,
    },
  },
};
</script>

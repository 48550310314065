import { Joyhttp, http, dataCenterHttp } from '../fetch.js';

//教师
export const teacher = {
  get_all_teachers (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/teachers/get_all_teachers_v6',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_recommend_teachers (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post('/interface/hybrid/Recommend_Teachers_Interface/get_recommended_teachers', postData)
        .then(result => {
          if (result) {
            if (result.errcode != 200 && result.errcode != 1007
              && result.errcode != 1008) {
              reject(result.errmsg);
            } else {
              resolve(result.data);
            }
          }
        })
        .catch(function (error) {
          let errmsg = error.message || error.msg || error;
          reject(errmsg);
        });
    });
  },
  get_all_teachers_json (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/teachers/get_all_teachers_json_v5',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_teacher_details (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/teachers/get_teacher_details',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_teacher_comments (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/teachers/get_teacher_comments',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  toggle_favorite_teacher (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/teachers/toggle_favorite_teacher',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_teacher_grades (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/teachers/get_teacher_grades_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200 && result.errcode != 1007
            && result.errcode != 1008) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 外教资质
  get_teacher_certificate (postData) {
    return new Promise((resolve, reject) => {
      dataCenterHttp.post(
        '/api/Tools/getArrTeacherInfo',
        postData
      ).then(result => {
        if (result) {
          if (result.code != 200) {
            reject(result.message);
          } else {
            resolve(result.data);
          }
        } else {
          reject(result.message);
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  }
};
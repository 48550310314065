import quick from '../quick.js'
import { Auth }  from '@/api/deviceBridge'

export const cacheAPI = {
	saveItem ( keyword, content ) {
		localStorage.setItem( keyword, content)

		if ( ! Auth.os.h5 ) {
			return new Promise ( ( resolve, reject ) => {
				let param = {
					name : 'saveItem',
					mudule : 'cache',
					isLongCb: 0,
					data : {
						key : keyword,
						value : content
					}
				}

				quick.callApi( param )
					.then( result => {
						resolve( result );
					}).catch( error => {
						reject( error.msg );
					}); 
			} );
		}
		
	},
	readItem ( keyword ) {
		return new Promise ( ( resolve, reject ) => {
			if ( ! Auth.os.h5 ) {
				let param = {
					name : 'readItem',
					mudule : 'cache',
					isLongCb: 0,
					data : {
						key : keyword
					}
				}

				quick.callApi( param )
					.then( result => {
						resolve( result.appresult[keyword] );
					}).catch( error => {
						reject( error.msg );
					}); 
			} else {
				resolve( localStorage.getItem( keyword ) );
			}
		} );
	},
	removeItem ( keyword ) {
		localStorage.removeItem( keyword )
		
		if ( ! Auth.os.h5 ) {
			return new Promise ( ( resolve, reject ) => {
				let param = {
					name : 'removeItem',
					mudule : 'cache',
					isLongCb: 0,
					data : {
						key : keyword
					}
				}

				quick.callApi( param )
					.then( result => {
						resolve( result );
					}).catch( error => {
						reject( error.msg );
					}); 
			} );
		}
	}
}
import { linkHttp, http, Joyhttp } from '../fetch.js';
import Url from '../config.js';
import { siteEnv } from "@/utils/tools";

//主页
export const home = {
  // 获取补课详情列表
  get_makeup_lists (postData) {
    return new Promise((resolve, reject) => {
      http.get(
        '/app/interface/chinese_lessons/index',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 签订协议
  sign_agreement (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/sign_agreement/sign',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 获取最新版本协议
  get_new_agreement (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/service_agreement/get_latest_item',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 是否签订最新版协议
  is_sign_agreement (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/sign_agreement/has_sign',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  // 获取用户签订的协议
  get_sign_agreement (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/sign_agreement/get_item',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_logo (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/Welcome/get_images_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_notification_preview (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/hybrid/Student/get_notification_preview',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_notification_count (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/hybrid/Student/get_notification_count',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_checkGuide (studentId) {
    return new Promise((resolve, reject) => {
      let postData = {
        student_id: studentId,
      };
      linkHttp.post(Url['checkGuide'], postData)
        .then(result => { resolve(result); })
        .catch(error => { reject(error); });
    });
  },
  getBannerImages (studentId, agentId) {
    return new Promise((resolve, reject) => {
      let postData = {
        from: siteEnv().includes('utalk') ? 'utalk' : 'joy',
        position: 'NEWBANNER',
        student_id: studentId,
        agent_id: agentId,
      };
      linkHttp.post(Url['cmsUrl'] + '/interface/api/cms/ad', postData)
        .then(result => { resolve(result); })
        .catch(error => { reject(error); });
    });
  },
  get_classes (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/Student_class/get_classes_v6',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_calendar (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/hybrid/Student/calendar',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_notif_detail (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/profile/get_notification_complete',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_notif_list (postData) {
    return new Promise((resolve, reject) => {
      http.post(
        '/app/interface/profile/get_notifications_v2',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_purchase_info (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/hybrid/Points_Expiration/get_purchase_info',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
  get_purchase_history (postData) {
    return new Promise((resolve, reject) => {
      Joyhttp.post(
        '/interface/hybrid/Points_Expiration/get_all_purchased',
        postData
      ).then(result => {
        if (result) {
          if (result.errcode != 200) {
            reject(result.errmsg);
          } else {
            resolve(result.data);
          }
        }
      }).catch(function (error) {
        let errmsg = error.message || error.msg || error;
        reject(errmsg);
      });
    });
  },
};

import VModal from 'vue-js-modal';
export default {
  install (Vue, options) {
    Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
    Vue.prototype.utalkModalAlert = function (options) {
      this.$modal.show(
        {
          template:
            `<div class="${getThisPage().$store.getters.getScreenTheme == 'DARK' ? 'dark' : ''}">
						<yd-flexbox>
							<yd-flexbox-item>
								<div class="modal-center" v-html="message"></div>
							</yd-flexbox-item>
						</yd-flexbox>
						<yd-flexbox>
							<yd-flexbox-item>
								<div class="modal-right" @click="submitFun($event) && $emit('close')">{{submit}}</div>
							</yd-flexbox-item>
						</yd-flexbox>
                    </div>`,
          props: ['message', 'submit', 'submitFun'],
        },
        {
          message: typeof options == "string" ? options : options.msg || '祝你学业有成',
          submit: options.submit || '确定',
          submitFun: options.submitFun || function ($emit) { return true; }
        },
        {
          name: 'ExampleModal',
          width: '80%',
          height: 'auto',
          classes: 'modal-message-box',
          adaptive: true,
          reset: true,
          scrollable: true,
          adaptive: true,
          clickToClose: false
        }
      );
    };
  }
};

function getThisPage () {
  let thisPage = window.Vue;
  if (window.Vue) { thisPage = window.Vue; console.log('Vue'); }
  else if (window.Vue_class_list) { thisPage = window.Vue_class_list; console.log('Vue_class_list'); }
  else if (window.Vue_ai_lesson) { thisPage = window.Vue_ai_lesson; console.log('Vue_ai_lesson'); }
  else if (window.Vue_profile) { thisPage = window.Vue_profile; console.log('Vue_profile'); }
  else if (window.Vue_login) { thisPage = window.Vue_login; console.log('Vue_login'); }
  else if (window.Vue_reset) { thisPage = window.Vue_reset; console.log('Vue_reset'); }
  else if (window.Vue_audio) { thisPage = window.Vue_audio; console.log('Vue_audio'); }
  else if (window.Vue_booking) { thisPage = window.Vue_booking; console.log('Vue_booking'); }
  else if (window.Vue_calendar) { thisPage = window.Vue_calendar; console.log('Vue_calendar'); }
  else if (window.Vue_call_history) { thisPage = window.Vue_call_history; console.log('Vue_call_history'); }
  else if (window.Vue_homework) { thisPage = window.Vue_homework; console.log('Vue_homework'); }
  else if (window.Vue_notification) { thisPage = window.Vue_notification; console.log('Vue_notification'); }
  else if (window.Vue_online_grade) { thisPage = window.Vue_online_grade; console.log('Vue_online_grade'); }
  else if (window.Vue_purchase) { thisPage = window.Vue_purchase; console.log('Vue_purchase'); }
  else if (window.Vue_teachers_list) { thisPage = window.Vue_teachers_list; console.log('Vue_teachers_list'); }
  return thisPage;
}